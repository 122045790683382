import { useNavigate } from "react-router-dom";

export default function GoBackButton(){

  let navigate = useNavigate();

  const goBack = () => navigate(-1);

  return(
    <a className={'home-links button-go-back'} onClick={goBack}>Retour</a>
  )
}