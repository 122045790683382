import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    realm: 'master',
    url: 'https://auth.daisywheel.fr/auth/',
    clientId: 'beehive',
});


export default keycloak;
