import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Disconnect from '../components/Disconnect';
import AppDatas from '../data/AppDatas.json';
import '../style.css';


export default function Home(props) {
    const { user } = props;
    const [beehiveRole, setBeehiveRole] = useState('');

    useEffect(() => {
        if (user && user.resource_access && user.resource_access.beehive) {

            const beehiveRoles = JSON.stringify(user.resource_access.beehive.roles).slice(1,-1).replace(/"/g, '');
            if (beehiveRoles) {
                setBeehiveRole(beehiveRoles);
            }
        }
    }, [user]);

    if (!user || !user.preferred_username) {
        return <div>Chargement...</div>;
    }


    if (beehiveRole) {
        return (
            <div className="home-container">
                <h1>{AppDatas.main.name}</h1>
                <h2>Qrcode API</h2>
                <span>Générateur de QRcode pour le SAV</span>

                <div className="user-info">
                    <p>{user.preferred_username}</p>
                    {beehiveRole && <p>{beehiveRole}</p>}
                </div>

                <br /><br /><br />
                <span>Users</span>
                <Link to="/generator" className="home-links">
                    Générateur
                </Link>
                <br /><br />
                <Disconnect />
            </div>
        );
    }

    return (
        <div className="access-denied">
            <h1>Accès refusé</h1>
            <p>Vous n'avez pas accès à la page Beehive.</p>
            <Disconnect />
        </div>
    );
}
