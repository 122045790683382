import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Navigate } from "react-router-dom";

const Login = () => {
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (!keycloak.authenticated) {
            keycloak.login();
        }
    }, [keycloak]);

    return <Navigate to="/home" />;
};

export default Login;
