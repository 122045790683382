import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import qrcodePlaceholder1 from '../assets/img/qrcodePlaceholder_fr1.png';
import qrcodePlaceholder5 from '../assets/img/qrcodePlaceholder_fr5.png';
import qrcodePlaceholder10 from '../assets/img/qrcodePlaceholder_fr10.png';
import data from "../data/serverConf.json";
import whatsappTemplate from "../data/whatsapp_template_lang.json";
import GoBackButton from "../components/GoBackButton";
import '../style.css';
import JSZip from 'jszip';
const QRCode = require('qrcode');





let axiosRequest = data.Server.protocol + '://' + data.Server.IP + ':' + data.Server.port;

Axios.defaults.withCredentials = true;

export default function Generator() {
  /* - send states - */
  const [sendSystem, setSendSystem] = useState('mail');
  const [mailClient, changeMail] = useState('');
  const [phoneZoneClient, changePhoneZone] = useState('');
  const [phoneNumberClient, changePhoneNumber] = useState('');
  const [clientStatus, setClientStatus] = useState('');
  const [sendStatus, setSendStatus] = useState('');
  const [qrcodeId, setQRCodeId] = useState('');
  const [sendLang, setSendLang] = useState('');

  /* - qrcode states - */
  //const [nameQRCode, setNameQRCode] = useState('');
  //const [nameQRCode, setNameQRCode] = useState('YYYY-MM-DD-00h00m00s-description-nameItem.png');
  const [typeObject, setTypeObject] = useState('');
  const [desc, setDesc] = useState('');
  const [qrcode, setQRCode] = useState('');
  const [descCharLength, setDescCharLength] = useState('0/30');
  const [generateStatus, setGenerateStatus] = useState('');
  const [generateCounter, setGenerateCounter] = useState('');
  const [isLittleNumberQRCodes, setIsLittleNumberQRCodes] = useState(false);
  /*const [isFirstQRCode, setIsFirstQRCode] = useState(false);*/
  const [qrcodeSrc, setQrcodeSrc] = useState(qrcodePlaceholder1);
  const [nbQRCode, setNbQRCode]=useState(0);
  const [isGenerateButtonClicked, setIsGenerateButtonClicked] = useState(false);
  const [selectedQRValue, setSelectedQRValue] = useState("");
  const [generatedQRCodes, setGeneratedQRCodes] = useState([]);
  const [activeButton, setActiveButton] = useState("RAZ");
  const [isQRCodeGenerated, setIsQRCodeGenerated] = useState(false);
  const [nameQRCode, setNameQRCode]=useState([]);
  let isGenerateEnabled = (typeObject && nbQRCode > 0);
  const isActionEnabled = isQRCodeGenerated;




  let timeNow = '';
  let descEdited;


  const  setImageValue=(value)=>{
    switch (value) {
      case "1": setQrcodeSrc(qrcodePlaceholder1);
      break;
      case "5": setQrcodeSrc(qrcodePlaceholder5);
      break;
      case "10": setQrcodeSrc(qrcodePlaceholder10);
      break;
      default:
        setQrcodeSrc(qrcodePlaceholder1);
    }
  }

  const remiseAzero = () => {
    setNbQRCode(0);
    isGenerateEnabled=false;
    setDesc('');
    setSendSystem('mail');
    changeMail('');
    changePhoneZone('');
    changePhoneNumber('');
    setDesc('');
    setDescCharLength('0/30');
    setSelectedQRValue("");


    setQrcodeSrc(qrcodePlaceholder1);


    setClientStatus('');
    setSendStatus('');
    setGenerateStatus('');
    setGenerateCounter('');
    setIsLittleNumberQRCodes(false);
    setIsGenerateButtonClicked(false);

    const radioButtons = document.querySelectorAll('input[type=radio]');
    radioButtons.forEach(radio => {
      radio.checked = false;
    });


    const defaultRadio = document.getElementById('sendWay1');

    if (defaultRadio)
      defaultRadio.checked = true;

    setIsQRCodeGenerated(false);
    setActiveButton("RAZ");
    setTypeObject('');

    //window.location.reload();
  };



  const sendQRCode = () => {
    setActiveButton("sendQRCode");

    if (!generatedQRCodes || generatedQRCodes.length === 0) {
      setSendStatus("Aucun QRCode à envoyer !");
      setIsQRCodeGenerated(false);
      return;
    }

    generatedQRCodes.forEach((qrcode, index) => {
      Axios.post(axiosRequest + "/api/sendqrcode", {
        qrcode: qrcode.data,
        qrcodeName: qrcode.name,
        sendWay: sendSystem,
        mailClient: mailClient,
        phoneZoneClient: phoneZoneClient,
        phoneNumberClient: phoneNumberClient,
        qrcodeId: qrcode.id
      }).then((response) => {
        if (response.data) {
          console.log("QR Code envoyé avec succès :", qrcode.name);
        } else {
          console.error("Échec de l'envoi du QR Code :", qrcode.name);
        }
        // Gestion des messages de statut
        if (index === generatedQRCodes.length - 1) {
          setSendStatus('Envoi terminé. Vérifiez la console pour plus de détails.');
          setTimeout(() => {
            setSendStatus('');
          }, 3000);
        }
      }).catch((error) => {
        console.error('Erreur pendant l\'envoi des QR codes :', error);
        setSendStatus('Erreur lors de l\'envoi des QR Codes. Vérifiez la console pour les détails.');
      });
    });

    setGeneratedQRCodes([]);
    setIsQRCodeGenerated(false);
    setActiveButton("");
  };


  const sendTemplateMessage = () => {
    if(!sendSystem === 'whatsapp'){
      setSendStatus("Error, wrong send system selected, please refresh the page");
    }else{
      Axios.post(axiosRequest + "/api/sendtemplatemessage", {
        sendWay: sendSystem,
        phoneZoneClient: phoneZoneClient,
        phoneNumberClient: phoneNumberClient,
        sendLang: sendLang
      }).then((response) => {
        if (response.data) {
	  if(response.data.error != true){
	    setClientStatus('');
	    setSendStatus(response.data.message);
	    setTimeout(() => {
	      setSendStatus('');
	    }, 3000);
	  }else{
	    setClientStatus('Waiting for client response...');
            setSendStatus(response.data.message);
            setTimeout(() => {
              setSendStatus('')
            }, 3000);
	  }
        } else {
          setSendStatus('We couldn\'t send your request to the server, If the problem persists, please contact the developer.');
        }
        console.log(response);
      });
    }
  }



  const downloadToPng = async () => {

    if (generatedQRCodes.length === 0) {
      setGenerateStatus("Aucun QR Code généré à télécharger !");
      return;
    }
    if (generatedQRCodes.length === 1) {
      // Télécharger un seul QR Code en tant que PNG
      const qrCode = generatedQRCodes[0];
      try {
        const response = await Axios.get(axiosRequest + "/api/downloadinpng", {
          params: { name: qrCode.name },
          responseType: 'blob'
        });

        if (response.data) {
          downloadBlob(response.data, qrCode.name);
        }
      } catch (error) {
        console.error('Erreur pendant le téléchargement du QR code :', error);
      }
    } else {
      // Télécharger plusieurs QR Codes dans un fichier ZIP
      const zip = new JSZip();

      for (const qrCode of generatedQRCodes) {
        try {
          const response = await Axios.get(axiosRequest + "/api/downloadinpng", {
            params: { name: qrCode.name },
            responseType: 'blob'
          });

          if (response.data) {
            zip.file(qrCode.name, response.data, { binary: true });
          }
        } catch (error) {
          console.error('Erreur pendant le téléchargement du QR code :', error);
        }
      }

      zip.generateAsync({ type: "blob" }).then(function(content) {

        const zipFileName = generatedQRCodes.length > 0
            ? generatedQRCodes[0].name.replace('.png', '.zip')
            : "qrcodes.zip";
        saveAs(content, zipFileName);
      });
    }

  };

  const downloadBlob = (blob, fileName) => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: 'image/png' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  };





  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };


  const generateQRCode = async () => {
    if (!isGenerateEnabled) return;
    setActiveButton("generateQRCode");
    setIsLittleNumberQRCodes(false);

    if (typeObject === '') {
      setGenerateStatus('Veuillez choisir un objet et le nombre de QR code à générer !');
      return;
    }
    setGenerateStatus('');
    setGenerateCounter('');
    timeNow = getTimeNow();
    descEdited = desc === '' ? '' : desc.replace(/ /g, '_');

    const newGeneratedQRCodes = []; // Pour stocker les informations des QR Codes générés

    for (let i = 1; i <= parseInt(nbQRCode); i++) {
      const uniqueId = generateUniqueId();
      const uniqueData = `id=${uniqueId}&desc=${descEdited}&type=${typeObject}`;
      const nameQRCode = `${timeNow}-${descEdited}-${typeObject}-${i}.png`;

      try {
        const response = await Axios.post(axiosRequest + "/api/generateqrcode", {
          typeObject: typeObject,
          name: nameQRCode,
          data: uniqueData
        });

        let qrcodeOptions = {
          errorCorrectionLevel: 'H',
          type: 'image/png',
          quality: 1,
          margin: 4,
          width: 256,
          height: 256,
          color: {
            dark:"#000000",
            light:"#ffffff"
          }
        };

        const qrcodeDataURL = await QRCode.toDataURL(response.data.qrcode, qrcodeOptions);

        newGeneratedQRCodes.push({
          name: nameQRCode,
          data: qrcodeDataURL
        });

        setGenerateStatus(response.data.message);
        setGenerateCounter(`${typeObject.toUpperCase()} - ${response.data.counter}/999999 QRCodes disponibles`);

        if (response.data.counter <= 10000) {
          setIsLittleNumberQRCodes(true);
        }

      } catch (error) {
        console.error('Erreur pendant la génération des QR codes :', error);
        setGenerateStatus('Erreur lors de la génération des QR Codes.');
        break;
      }
    }

    setGeneratedQRCodes(newGeneratedQRCodes); // Mettre à jour l'état avec les nouveaux QR Codes générés
    setQrcodeSrc(newGeneratedQRCodes[0]?.data); // Afficher le premier QR Code généré
    setNameQRCode(newGeneratedQRCodes[newGeneratedQRCodes.length - 1]?.name);
    setActiveButton("");
    setIsQRCodeGenerated(true);
  };





  const getTimeNow = () => {
    var today = new Date(),
      month = '' + (today.getMonth() + 1),
      day = '' + today.getDate(),
      year = today.getFullYear(),
      hours = today.getHours(),
      minutes = today.getMinutes(),
      seconds = today.getSeconds();

    if (month.length < 2){
      month = '0' + month;
    }
    if (day.length < 2){
      day = '0' + day;
    }
    let yearNow = today.getFullYear();
    //var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var date = [year, month, day].join('-');
    //var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var time = hours + 'h' + minutes + 'm' + seconds + 's';
    var dateTime = date+'-'+time;
    //console.log(dateTime);
    return dateTime;
  }

  const updateDesc = () => {
    const textarea = document.getElementById('description');
    const charCounter = document.getElementById('descCharLengthP');

    if(textarea.value.length !== 0){
      if(textarea.value.length === 30){
        textarea.style.color = 'green';
        charCounter.style.color = 'green';
      }else if(textarea.value.length > 24 && textarea.value.length < 30){
        textarea.style.color = '#ff7b00';
        charCounter.style.color = '#ff7b00';
      }/*else if(textarea.value.length > 19 && textarea.value.length < 25){
      textarea.style.color = '#ebc000';
      charCounter.style.color = '#ebc000';
    }*/else if(textarea.value.length > 30){
        textarea.style.color = 'red';
        charCounter.style.color = 'red';
      }else{
        textarea.style.color = 'black';
        charCounter.style.color = 'black';
      }

      let valueToDelete = 50 - textarea.value.length;
      let goodString = textarea.value.substr(0, 30);
      setDesc(goodString);
      setDescCharLength(textarea.value.length + '/30');
    }else{
      setDesc('description');
      textarea.style.color = 'black';
      charCounter.style.color = 'black';
      setDescCharLength('0/30');
    }
  }

  const updateName = () => {
    timeNow = getTimeNow();
    const descEdited = desc.replace(/ /g, '_');
    //setNameQRCode(timeNow + '-' + descEdited + '-' + typeObject + '.png');
    //nameQRCode = timeNow + '-' + descEdited + '-' + typeObject + '.png';
    alert("In updateName nameQRCode : " + nameQRCode);

  }

  const resetSendForm = () => {
    setSendSystem('mail');
    setSendStatus('');
    changeMail('');
    changePhoneZone('');
    changePhoneNumber('');
  }



  return(
      <div id={'main'} className={'main'}>
        <GoBackButton/>
        <div id={'left-block'} className={'left-block block'}>
          {/*<div id={'qrcode-preview'}>
            <img id={'qrcode-display'} src={qrcodeSrc} alt={'Qrcode display area'} />
          </div>*/}
          <div className={'left-inside'}>


              <form>
                <fieldset>

                  <legend>Mode de recupération</legend>
                  <div>
                    <input
                        type="button"
                        id="downloadQRCode"
                        className={`button-right btn`}
                        value="Télécharger en .png"
                        onClick={() => {
                          downloadToPng();
                          setActiveButton("downloadQRCode");
                        }}
                        disabled={!isActionEnabled}
                    />
                  </div>

                </fieldset>

              </form>
            <br/>
            <br/>

            <form>
              <fieldset id={'sendWaySelector'} onChange={(e) => {
                setSendSystem(e.target.value);
              }}>
                <legend>Mode d'envoi</legend>
                <div>
                  <input type={'radio'} id={'sendWay1'} name={'radio-btn'} value={'mail'} defaultChecked={true} />
                  <label htmlFor={'sendWay1'}>Mail</label>
                </div>
                {data.Server.WA_ENABLED ?
                    <div>
                      <input type={'radio'} id={'sendWay2'} name={'radio-btn'} value={'whatsapp'} />
                      <label htmlFor={'sendWay2'}><strike>WhatsApp</strike></label>
                    </div>
                    :
                    <></>}
              </fieldset>

              <fieldset id='sendWayOptions'>
                <div>
                  {sendSystem === "mail" ?
                      <div>
                        <label htmlFor={'mailInput'}>Mail : </label>
                        <input type={'email'} id={'mailInput'} placeholder={'example@test.com'} onChange={(e) => {changeMail(e.target.value);}} />
                      </div>
                      :
                      <></>}
                  {sendSystem === "whatsapp" ?
                      <div>
                        <label htmlFor={'whatsappPhoneZoneInput'}>Indicateur téléphonique : </label>
                        <input type={'text'} id={'whatsappPhoneZoneInput'} placeholder={'33'}  maxLength={'5'} size={'5'} onChange={(e) => {changePhoneZone(e.target.value);}} />
                        <br/>
                        <label htmlFor={'whatsappPhoneNumberInput'}>Numéro de téléphone : </label>
                        <input type={'text'} id={'whatsappPhoneNumberInput'} placeholder={'123456789'} maxLength={'14'} size={'14'} onChange={(e) => {changePhoneNumber(e.target.value);}} />
                        <br/>
                        <fieldset id={'lang-selector'}
                                  onChange={(e) => {
                                    setSendLang(e.target.value);
                                  }}>
                          <legend>Choisir une langue :</legend>
                          <div className={'lang-options-container'} style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                            {whatsappTemplate.map((obj, i) => {
                              return(
                                  <div key={i}>
                                    <input type={"radio"} id={obj.id} name={"lang"} value={obj.value} />
                                    <label htmlFor={obj.id} className={'flags'}>{obj.flag}</label>
                                  </div>
                              )
                            })}
                          </div>
                        </fieldset>
                      </div>
                      :
                      <></>}
                </div>
              </fieldset>

              <div
                  id={'left-buttons'}
                  className={'left-buttons'}
              >
                {sendSystem === 'whatsapp' ?
                    <div>
                      <span style={{color: "red"}}>D'abord envoyer un message template, puis attendre que le client réponde avant de lui envoyer un qrcode</span>
                      <br/>
                      <input
                          type={'button'}
                          id={'sendTemplateMessage'}
                          className={'button-left btn'}
                          style={{height: "60px", whiteSpace: "normal"}}
                          value={'Envoyer template'}
                          onClick={sendTemplateMessage}
                      />
                      <br/>
                      <span style={{color: clientStatus === 'Client message received' ? 'green' : 'black'}}>{clientStatus}</span>
                    </div>
                    :<></>
                }
                <br/>
                <div className="button-container">
                  <input
                      type="button"
                      id="sendQRCode"
                      className={`button-right btn ${!isQRCodeGenerated ? 'button-disabled' : ''}`}
                      style={{ marginRight: '20px' }}
                      value="Envoyer"
                      onClick={() => {
                        sendQRCode();
                        setActiveButton("sendQRCode");
                      }}
                      disabled={!isActionEnabled}
                  />

                  <input
                      type="reset"
                      id="resetForm"
                      className="button-left btn orange-button"
                      value="Reset"
                  />
                </div>

              </div>
            </form>
            <span>{sendStatus}</span>
	  {/*<p>France: +33 01 23 45 67 89</p>
	  <p>Allemagne: +49 01 234 56 78 9</p>
	  <p>Uk: +44 01 2345 6789</p>
	  <p>Us: +1 012 345 6789</p>*/}
	  </div>
      </div>
      {/*<div id={'middle-line'} className={'middle-line'}>
      </div>*/}
      <div id={'right-block'} className={'right-block block'}>
        <div className={'right-inside'}>
          <div id={'qrcode-preview'}>
            <img id={'qrcode-display'} src={qrcodeSrc} alt={'Qrcode display area'} />
          </div>
          <br/>
          <span id={'nameQRCode'}>{nameQRCode}</span>
          <input
              type="button"
              id="remiseAzero"
              className={`button-right btn orange-button`}
              value="Réinitialiser"
              onClick={remiseAzero}
          />


          {/*<select
            id={'typeObjectSelect'}
            className={'typeObjectSelect'}
            onChange={updateTypeObject}
          >
              <option value={''}>Sélectionnez un objet</option>
              <option value={'bottle0-5L'}>Bouteille 0,5L</option>
              <option value={'bottle1L'}>Bouteille 1L</option>
              <option value={'valve'}>Valve</option>
              <option value={'CH'}>Produit H2O</option>
              <option value={'CS'}>Produit solvant</option>
            </select>*/}

          <fieldset id={'typeObjectSelector'} onChange={(e) => {
            setTypeObject(e.target.value);
          }}>
          <legend>Sélectionner un objet</legend>
          <div>
            <input type={'radio'} id={'itemType1'} name={'radio-btn'} value={'bottle05L'} />
            <label htmlFor={'itemType1'}>Bouteille 0.5L</label>
          </div>
          <div>
            <input type={'radio'} id={'itemType2'} name={'radio-btn'} value={'bottle1L'} />
            <label htmlFor={'itemType2'}>Bouteille 1L</label>
          </div>
          <div>
            <input type={'radio'} id={'itemType3'} name={'radio-btn'} value={'valve'} />
            <label htmlFor={'itemType3'}>Valve</label>
          </div>
          <div>
            <input type={'radio'} id={'itemType4'} name={'radio-btn'} value={'producth2o'} />
            <label htmlFor={'itemType4'}>Produit H<sub>2</sub>O</label>
          </div>
          <div>
            <input type={'radio'} id={'itemType5'} name={'radio-btn'} value={'productsolv'} />
            <label htmlFor={'itemType5'}>Produit solvant</label>
          </div>
        </fieldset>

          <fieldset id="nbQRCodeSelector" onChange={(e) => {
            setNbQRCode(parseInt(e.target.value));
            setImageValue(e.target.value);
          }}>
            <legend>Sélectionner le nombre de QR code</legend>
            <div className="radio-item">
              <input
                  type="radio"
                  id="qrcodeType1"
                  name="radio-btn-qr"
                  value="1"
                  onChange={() => setSelectedQRValue("1")}
              />
              <label htmlFor="qrcodeType1">1</label>
            </div>
            <div className="radio-item">
              <input
                  type="radio"
                  id="qrcodeType5"
                  name="radio-btn-qr"
                  value="5"
                  onChange={() => setSelectedQRValue("5")}
              />
              <label htmlFor="qrcodeType5">5</label>
            </div>
            <div className="radio-item">
              <input
                  type="radio"
                  id="qrcodeType10"
                  name="radio-btn-qr"
                  value="10"
                  onChange={() => setSelectedQRValue("10")}
              />
              <label htmlFor="qrcodeType10">10</label>
            </div>
          </fieldset>




          <div id={'desc-group'} className={'desc-group'}>
          <textarea
            id={'description'}
            className={'textarea-desc'}
            name={'desc'}
            rows={'2'}
            cols={'32'}
            maxLength={'50'}
            placeholder={'Description du QRCode (facultatif)'}
            onChange={updateDesc}
            value={desc}
            resizable={'false'}
            spellCheck={'false'}
          >
          </textarea>
          <span id={'descCharLengthP'}>{descCharLength}</span>
        </div>

          <input
              type={'button'}
              id={'generateQRCode'}
              className={`button-right btn ${!isGenerateEnabled ? 'button-disabled' : ''}`}
              value={'Générer'}
              onClick={() => {
                generateQRCode();
                setIsGenerateButtonClicked(true);
                setActiveButton("generateQRCode");
              }}

              disabled={!isGenerateEnabled}
          />

          <span>{generateStatus}</span>
        <br/>
        <span id={'generator-counter'} style={{ color: isLittleNumberQRCodes ? 'red' : 'black'}}>{generateCounter}</span>
        </div>
      </div>
    </div>
  )
}
